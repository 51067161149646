<template>
    <div class="NoticeList">
        <div class="main">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/zixun' }">资讯中心</el-breadcrumb-item>
                <el-breadcrumb-item>实时资讯</el-breadcrumb-item>
            </el-breadcrumb>
            <el-card class="box-card">
                <div class="main-title">实时资讯</div>
                <div class="main-body">
                    <div class="body-table">
                        <div class="table-list">
                            <div class="card-message mt-3 mb-3" v-for="(item,index) in XiangqingList" :key="index">
                                <div style="float:left; border:1px solid #8c939d;height: 60px;text-align: center;margin-right: 20px;width: 100px" >
                                    <img v-if="item.imageUrl != ''&& item.imageUrl != null" :src="item.imageUrl" class="card_imgsize"/>
                                    <img v-else :src="require('../assets/imgs/bg-new.png')" class="card_imgsize"/>
                                </div>
                                <div @click="gotoDetails(item.newsreleasename,item.content,'info')" class="card-message_t card_size cursors">{{item.newsreleasename}}</div>
                                <div class="card-message_b card_size">
                                     <p v-html="item.content.substr(0,50)"></p>....
                                     </div>
                                <div style="overflow: hidden;font-size: 14px;margin-top: 4px">
                                    <!--                                    <a>......[详细]</a>-->
                                    <!-- <div style="float: right;color: #c0bdbd">
                                        {{item.releaseTime}}
                                    </div> -->
                                </div>
                            </div>

                        </div>
                        <!--    分页-->
                        <div class="block" align="right">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageIndex"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="totalPage"
                                    layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                XiangqingList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                activeNames: ['1'],
                //分页数据
                dataForm: {
                    productid: '',
                    delivery: '',
                    outbidstate: '',
                    splitstate: ''
                },
                dataListLoading: false,
                qualityTableStyle: {
                    'background': 'whitesmoke',
                    'font-weight': 'bold',
                    'font-size': ' 12px',
                    'font-family': "Microsoft YaHei",
                    'color': '#333333',
                    'text-align': 'center',
                },
            }
        },
        mounted() {
            this.getDataList()
        },
        methods: {
            // 新闻资讯
            getDataList() {
            this.$fetch('/shoppingui/bidShopping/getNewReleasePage', {
                'page': this.pageIndex,
                'limit': this.pageSize,
            }, true).then((data) => {
                if (data && data.code === 0) {
                     this.XiangqingList = data.data.list;
                     this.totalPage = data.data.total
                } else {
                this.XiangqingList = []
                this.$message({
                    message: data.msg + '获取失败',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getDataList()
            },
            gotoDetails(name, url,title) {
                let queryData = {};
                queryData.url = url;
                queryData.newsReleaseName = name;
                queryData.title = title;
                this.$router.push({path: "/zxXiangQing", query: queryData});
            },

        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/bidding.scss";
    @import "../assets/css/page/NoticeList.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }
</style>
